exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adventure-tsx": () => import("./../../../src/pages/adventure.tsx" /* webpackChunkName: "component---src-pages-adventure-tsx" */),
  "component---src-pages-automotive-tsx": () => import("./../../../src/pages/automotive.tsx" /* webpackChunkName: "component---src-pages-automotive-tsx" */),
  "component---src-pages-beauty-tsx": () => import("./../../../src/pages/beauty.tsx" /* webpackChunkName: "component---src-pages-beauty-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tech-tsx": () => import("./../../../src/pages/tech.tsx" /* webpackChunkName: "component---src-pages-tech-tsx" */),
  "component---src-templates-photo-tsx": () => import("./../../../src/templates/photo.tsx" /* webpackChunkName: "component---src-templates-photo-tsx" */)
}

